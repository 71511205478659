.about-us {
  @media (max-width: 1160px) {
    &__main-text {
      width: 90%;
    }
  }

  @media (max-width: 840px) {
    &__main-text {
      width: 100%;
    }
  }

  &__our-team {
    @media (max-width: 1440px) {
      width: 60%;
    }

    @media (max-width: 1320px) {
      width: 70%;
    }

    @media (max-width: 1020px) {
      width: 80%;
    }

    @media (max-width: 870px) {
      width: 90%;
    }

    @media (max-width: 767px) {
      width: 100%;

      .team-member-photo {
        width: 120px;
        max-width: 120px;
        height: 120px;
        max-height: 120px;
      }

      .team-member-name {
        font-size: 1rem;
      }

      .team-member-text {
        font-size: 1rem;
      }
    }

    @media (max-width: 600px) {
      .team-member-photo {
        width: 120px;
        max-width: 120px;
        height: 120px;
        max-height: 120px;
      }
    }
  }

  &__link-wrapper {
    @media (max-width: 600px) {
      margin-top: 0;
    }
  }
}