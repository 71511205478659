.blog-wrapper {
  @media (max-width: 1500px) {
    width: 65%;
  }

  @media (max-width: 1200px) {
    width: 75%;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.blog-title {
  line-height: 1.2 !important;

  @media (max-width: 1050px) {
    font-size: 2rem;
  }

  @media (max-width: 574px) {
    font-size: 1.75rem;
  }
}

.blog-content {
  * {
    font-family: var(--font-base) !important;
  }

  p {
    margin-bottom: 1rem !important;
    
    color: var(--color-black);

    img {
      margin: 2.5rem 0;

      border: 4px solid var(--color-black);
      border-radius: 0.5rem;
    }

    span {
      line-height: 1.8;
    }
  }
}