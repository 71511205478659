.blog-preview-box {
  $this: &;

  &--highlighted {
    #{$this}__title {
      // blog title
      h2 {
        font-size: 1.5rem;
        line-height: 2rem;

        @media (max-width: 768px) {
          margin-bottom: 1rem;
        }

        @media (max-width: 400px) {
          font-size: 1.2rem;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &:not(&--highlighted) {
    width: calc(50% - 2.5rem);
    height: 340px;
    margin-bottom: 2.5rem;

    &:nth-child(2n+1) {
      margin-right: 2.5rem;
    }

    filter: grayscale(.9);

    &:hover {
      filter: grayscale(0);
    }

    @media (max-width: 1280px) {
      width: calc(50% - 1.25rem);
    }

    @media (max-width: 640px) {
      width: 100%;
    }

    #{$this}__title {
      // blog title
      h2 {
        width: 200px;
  
        font-size: 1rem;
        line-height: 1.5rem; 

        @media (max-width: 1690px) {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
  
      // date created
      span {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      @media (max-width: 1690px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    #{$this}__excerpt {
      display: none;
    }
  }
}