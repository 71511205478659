.photo-preview-box {
  @media (min-width: 1801px) {
    width: calc(calc(100% / 5) - 2rem);
    
    &:nth-child(5n) {
      margin-right: 0;
    }

    &:not(&:nth-child(5n)) {
      margin-right: 2.5rem;
    }
  }

  @media (min-width: 1451px) and (max-width: 1800px) {
    width: calc(calc(100% / 4) - 2rem);

    &:nth-child(4n) {
      margin-right: 0;
    }
  
    &:not(&:nth-child(4n)) {
      margin-right: 2.5rem;
    }
  }

  @media (min-width: 1181px) and (max-width: 1450px) {
    width: calc(calc(100% / 3) - 1.8rem);

    &:nth-child(3n) {
      margin-right: 0;
    }
  
    &:not(&:nth-child(3n)) {
      margin-right: 2.5rem;
    }
  }

  @media (min-width: 631px) and (max-width: 1180px) {
    width: calc(calc(100% / 2) - 1.5rem);

    &:nth-child(2n) {
      margin-right: 0;
    }
  
    &:not(&:nth-child(2n)) {
      margin-right: 2.5rem;
    }
  }

  @media (max-width: 630px) {
    width: 100%;
  }
}