.header-title {
  @media (max-width: 1787px) {
    padding: 0;

    font-size: 3rem;
    line-height: 1;

    img {
      transform: scale(.8);
    }
  }

  @media (max-width: 1467px) {
    font-size: 2.5rem;
    border-bottom-width: 3px;

    img {
      transform: scale(.7);
    }
  }

  @media (max-width: 1050px) {
    font-size: 2rem;
  }

  @media (max-width: 574px) {
    font-size: 1.5rem;

    img {
      transform: scale(1.2);
    }
  }
}

.lang-selection-btns {
  @media (max-width: 1787px) {
    top: 1.4rem;

    button {
      font-size: 1rem;
    }
  }

  @media (max-width: 1467px) {
    top: 1.5rem;

    button {
      padding: 0.5rem 0.4rem;

      font-size: 0.8rem;
    }
  }
}

.nav-menu-wrapper {
  @media (max-width: 1787px) {
    .nav-menu-button, .nav-menu-social {
      font-size: 1.5rem;
      line-height: 1.3;
    }

    .nav-menu-button {
      padding: 0 1.75rem;
    }
  }

  @media (max-width: 1467px) {
    border-bottom-width: 3px;

    .nav-menu-button, .nav-menu-social {
      font-size: 1.2rem;
      line-height: 1.3;
      
      border-left-width: 3px;
    }

    .nav-menu-button {
      padding: 1px 1.5rem 0;

      &:last-child {
        border-right-width: 3px;
      }
    }
  }
}