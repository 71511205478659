.hero-mark {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;

  border: 20px solid var(--color-black);

  transform: translate(50%, 50%) rotate(45deg);
}