.hero-image {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  @media (max-width: 1023px) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.25rem;
  }
}

.hp-about-us {
  @media (max-width: 1160px) {
    &__main-text {
      width: 90%;
    }
  }

  @media (max-width: 840px) {
    &__main-text {
      width: 100%;
    }
  }

  .highlights-box {
    @media (max-width: 1440px) {
      width: 60%;
    }

    @media (max-width: 1160px) {
      width: 80%;
      margin-top: 2.5rem;
    }

    @media (max-width: 840px) {
      width: 100%;
    }

    @media (max-width: 768px) {
      h6 {
        font-size: 1.5rem;
      }

      p {
        font-size: 0.875rem;
      }
    }

    @media (max-width: 520px) {
      flex-direction: column;
      width: 50%;

      div {
        padding: 2rem;
      }

      div:not(:last-child) {
        border-right: 0;
        border-bottom: 4px solid var(--color-black);
      }
    }

    @media (max-width: 470px) {
      width: 70%;
    }

    @media (max-width: 390px) {
      width: 85%;
    }
  }

  &__our-team {
    @media (max-width: 1440px) {
      width: 60%;
    }

    @media (max-width: 1320px) {
      width: 70%;
    }

    @media (max-width: 1020px) {
      width: 80%;
    }

    @media (max-width: 870px) {
      width: 90%;
    }

    @media (max-width: 767px) {
      width: 100%;

      .team-member-photo {
        width: 150px;
        height: 150px;
      }

      .team-member-name {
        font-size: 1.25rem;
      }
    }

    @media (max-width: 600px) {
      & > div {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      .team-member-photo {
        width: 200px;
        height: 200px;
      }
    }
  }

  &__link-wrapper {
    @media (max-width: 600px) {
      margin-top: 0;
    }
  }

  &__photos {
    @media (max-width: 1600px) {
      width: 80%;
    }

    @media (max-width: 1440px) {
      width: 90%;
    }

    @media (max-width: 1300px) {
      a {
        margin-right: 2rem;
      }
    }

    @media (max-width: 1050px) {
      width: 100%;
    }

    @media (max-width: 930px) {
      flex-direction: column;
      width: 70%;

      a {
        margin-right: 0;
      }
    }

    @media (max-width: 930px) {
      width: 100%;
    }
  }

  &__photos + div {
    @media (max-width: 930px) {
      margin-top: 0;
    }
  }
}