.what-we-do {
  @media (max-width: 1600px) {
    flex-wrap: wrap;
    height: auto;
  }

  &__photo {
    @media (max-width: 1600px) {
      min-height: 400px;

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    @media (max-width: 1200px) {
      width: 100%;

      border-right: none;

      &:first-child {
        order: 1;

        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      &:not(:first-child) {
        order: 3;

        border-top: 4px solid var(--color-black);
        border-radius: 0;
      }

      &:nth-child(3) {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }
  
  &__text {
    position: absolute;
    width: 50%;

    @media (max-width: 1600px) {
      position: relative;
      width: 100%;

      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.25rem;

      p {
        max-width: 75%;
      }
    }

    @media (max-width: 1200px) {
      order: 2;

      p {
        max-width: 100%;
      }
    }
  }
}