.photo-wrapper {
  @media (max-width: 430px) {
    padding-top: 0;
  }
}

.photo-wrapper div:nth-child(2) {
  min-height: calc(100vh - 272px - 156px);

  @media (max-width: 915px) {
    flex-wrap: wrap;
    align-items: flex-start;

    .link-wrapper {
      order: 0;
      flex: 1 0 auto;
      align-self: flex-start;
      display: flex;
      margin-bottom: 2.5rem;
    }

    .link-wrapper:first-child {
      justify-content: flex-start;
    }

    .link-wrapper:last-child {
      justify-content: flex-end;
    }

    .photo {
      order: 1;
      flex: 1 0 100%;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    .link-wrapper {
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 430px) {
    .link-wrapper:first-child {
      margin-bottom: 0;
      transform: scale(70%) translateX(-40px);
    }

    .link-wrapper:last-child {
      margin-bottom: 0;
      transform: scale(70%) translateX(40px);
    }
  }
}